define("discourse/plugins/pushrbx-pika/discourse/templates/admin/plugins-mmdb-man", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="buttons" style="margin-top: 1em">
    <DButton
      @label="mmdb_man.update_btn"
      @action={{action "updateMmdb"}}
      @icon="wrench"
      @id="update-mmdb"
      @disabled={{this.isUpdating}}
    />
  </div>
  
  */
  {
    "id": "mtpmpaAe",
    "block": "[[[10,0],[14,0,\"buttons\"],[14,5,\"margin-top: 1em\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@label\",\"@action\",\"@icon\",\"@id\",\"@disabled\"],[\"mmdb_man.update_btn\",[28,[37,2],[[30,0],\"updateMmdb\"],null],\"wrench\",\"update-mmdb\",[30,0,[\"isUpdating\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/pushrbx-pika/discourse/templates/admin/plugins-mmdb-man.hbs",
    "isStrictMode": false
  });
});