define("discourse/plugins/pushrbx-pika/discourse/controllers/admin-plugins-mmdb-man", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/service", "@glimmer/tracking", "discourse-i18n"], function (_exports, _controller, _object, _ajax, _ajaxError, _service, _tracking, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsMmdbManController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isUpdating", [_tracking.tracked], function () {
      return false;
    }))();
    #isUpdating = (() => (dt7948.i(this, "isUpdating"), void 0))();
    updateMmdb() {
      this.isUpdating = true;
      return (0, _ajax.ajax)("/admin/plugins/mmdb-man/update", {
        type: "POST"
      }).then(result => {
        if (result.success) {
          this.dialog.alert({
            message: result.message || (0, _discourseI18n.i18n)("mmdb_man.update_success")
          });
        } else {
          this.dialog.alert({
            message: result.message || (0, _discourseI18n.i18n)("mmdb_man.update_error")
          });
        }
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.isUpdating = false;
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateMmdb", [_object.action]))();
  }
  _exports.default = AdminPluginsMmdbManController;
});